<template>
	<div class="bg-gray-800 flex flex-col gap-4 overflow-hidden">
		<div
			class="px-10 md:px-20 lg:px-24 pt-16 pb-10 grid grid-cols-1 md:grid-cols-3 gap-6 justify-items-start mx-auto w-full"
		>
			<!-- Socials -->
			<div class="w-full flex flex-col gap-3">
				<h3 class="text-gray-200 text-lg font-semibold leading-7">
					{{ $t("nav.socials.title") }}
				</h3>
				<ul class="flex flex-col gap-2 text-gray-400">
					<li>
						<a
							class="flex items-center gap-2 font-normal leading-6 hover:text-gray-300 cursor-pointer"
							target="__blank"
							href="https://www.instagram.com/fezz_dev/"
						>
							<i class="fab fa-instagram text-purple-500"></i>
							{{ $t("nav.socials.instagram") }}
						</a>
					</li>
					<li>
						<a
							class="flex items-center gap-2 font-normal leading-6 hover:text-gray-300 cursor-pointer"
							target="__blank"
							href="https://twitter.com/fezz02"
						>
							<i class="fab fa-x-twitter text-purple-500"></i>
							{{ $t("nav.socials.twitter") }}
						</a>
					</li>
				</ul>
			</div>

			<!-- Legal -->
			<div class="w-full flex flex-col gap-3">
				<h3 class="text-gray-200 text-lg font-semibold leading-7">
					{{ $t("common.naming.legal") }}
				</h3>
				<ul class="flex flex-col gap-2 text-gray-400">
					<li>
						<NuxtLink
							class="flex items-center gap-2 font-normal leading-6 hover:text-gray-300 cursor-pointer"
							href="https://www.iubenda.com/privacy-policy/75117075"
						>
							<i class="fas fa-shield-halved text-purple-500"></i>
							{{ $t("common.naming.privacy_policy") }}
						</NuxtLink>
					</li>
					<li>
						<NuxtLink
							class="flex items-center gap-2 font-normal leading-6 hover:text-gray-300 cursor-pointer"
							href="https://www.iubenda.com/privacy-policy/75117075/cookie-policy"
						>
							<i class="fas fa-cookie text-purple-500"></i>
							{{ $t("common.naming.cookie_policy") }}
						</NuxtLink>
					</li>
				</ul>
			</div>

			<!-- Contacts -->
			<div class="w-full flex flex-col gap-3">
				<h3 class="text-gray-200 text-lg font-semibold leading-7">Contacts</h3>
				<ul class="flex flex-col gap-2 text-gray-400">
					<li>
						<a
							class="flex items-center gap-2 font-normal leading-6 hover:text-gray-300 cursor-pointer"
							href="mailto:info@fezz.it"
						>
							<!-- <font-awesome
								:icon="['fas', 'envelope']"
								class="text-xl text-purple-400"
							/> -->
							info@fezz.it
						</a>
					</li>
					<li>
						<a
							class="flex items-center gap-2 font-normal leading-6 hover:text-gray-300 cursor-pointer"
							href="https://www.linkedin.com/in/federico-palcich/"
							target="__blank"
						>
							<!-- <font-awesome
								:icon="['fas', 'phone']"
								class="text-xl text-purple-400"
							/> -->
							Linkedin
						</a>
					</li>
				</ul>
			</div>
		</div>

		<!-- Divider -->
		<div
			class="border-t border-gray-500 flex flex-col md:flex-row items-center justify-center md:justify-between p-4"
		>
			<!-- Logo -->
			<svg
				class="fill-surface-600 dark:fill-surface-300"
				width="140"
				height="36"
				viewBox="0 0 140 36"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M30.729 5.27316C28.2118 2.75554 25.0045 1.04091 21.5128 0.346113C18.021 -0.348687 14.4017 0.00758329 11.1124 1.36986C7.82319 2.73214 5.01178 5.03929 3.03377 7.99939C1.05576 10.9596 0 14.4398 0 18C0 21.5602 1.05576 25.0404 3.03377 28.0006C5.01178 30.9607 7.82319 33.2679 11.1124 34.6301C14.4017 35.9924 18.021 36.3487 21.5128 35.6539C25.0045 34.9591 28.2118 33.2445 30.729 30.7268C34.104 27.3513 36 22.7733 36 18C36 13.2267 34.104 8.64874 30.729 5.27316Z"
				/>
			</svg>

			<!-- Copyright -->
			<span class="leading-normal text-gray-400 text-center md:text-left"
				>&copy; {{ new Date().getFullYear() }} Fezz, All rights reserved - P.IVA
				04155930920</span
			>
		</div>
	</div>
</template>
